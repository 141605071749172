import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"
import InfoModal from "../../../components/info-modal"

import { Vector3 } from "three"
import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import PanoContainer from "../../../components/pano-container"
import ToolBar from "../../../components/tool-bar"
import { Howl } from "howler"
import QuestionModal from "../../../components/question-modal"

// Custom Icons
import QuestionMark from "../../../../static/icons/question-mark-white.png"
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"
import EmergencyMark from "../../../../static/icons/emergency-mark-white.png"

export default class AntiSocialDistanceParty extends React.Component {
  constructor() {
    super()
    this.state = {
      currentQuestion: "",
      infoModalIsOpen: false,
      questionModalIsOpen: false,
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  createQuestionInfoSpot(question, position) {
    const questionInfoSpot = new Infospot(300, QuestionMark, false)
    questionInfoSpot.position.set(position.x, position.y, position.z)
    questionInfoSpot.addHoverText(question)
    questionInfoSpot.addEventListener("click", () => {
      this.setState({
        currentQuestion: question,
        questionModalIsOpen: true,
      })
    })

    return questionInfoSpot
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama(
      "/departments/anti-social-distance-party/panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(-4836.07, 1224.83, 181.84)
    doorInfoSpot.addHoverText("Go to the next department")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/disposable-crown/")
    })

    const emergencyExitSpot = new Infospot(350, EmergencyMark, false)
    emergencyExitSpot.position.set(-2194.61, 4076.67, 1878.47)
    emergencyExitSpot.addHoverText("Go to the emergency exit")
    emergencyExitSpot.addEventListener("click", () => {
      navigate("/departments/the-emergency-exit/")
    })

    // Question Marks
    const digitalBirthdayQuestion = this.createQuestionInfoSpot(
      "Celebrating a birthday digitally is as good as in 'real life'",
      new Vector3(4947.64, -495.73, 471.47)
    )

    const covidQuestion = this.createQuestionInfoSpot(
      "COVID-19 is messing with my birthday",
      new Vector3(0.17, 2808.95, -4131.92)
    )

    const isolationQuestion = this.createQuestionInfoSpot(
      "I understand better what birthdays mean to me, because of isolation",
      new Vector3(-2352.38, 2002.5, 3923.9)
    )

    const stressfulQuestion = this.createQuestionInfoSpot(
      "birthdays are actually less stressful because of COVID-19 restrictions",
      new Vector3(54.89, -2977.99, 4011.42)
    )

    const socialDistanceQuestion = this.createQuestionInfoSpot(
      "But when it's my birthday I am allowed to break the social distance rules",
      new Vector3(5885.01, -2919.76, -3958.14)
    )

    // Add the InfoSpots to the Main Panorama
    mainPanorama.add(doorInfoSpot)
    mainPanorama.add(emergencyExitSpot)
    mainPanorama.add(digitalBirthdayQuestion)
    mainPanorama.add(covidQuestion)
    mainPanorama.add(isolationQuestion)
    mainPanorama.add(stressfulQuestion)
    mainPanorama.add(socialDistanceQuestion)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    // Set initial Camera position
    this.setPositionToTarget(new Vector3(-4836.07, 1224.83, 181.84), viewer)

    viewer.add(mainPanorama)

    // Setup the new Howl.
    this.sound = new Howl({
      src: [
        "/departments/anti-social-distance-party/anti-social-distance-party.mp3",
      ],
    })

    // Play the sound.
    this.sound.play()

    // Fade In
    this.sound.fade(0.0, 0.5, 2000)
  }

  componentWillUnmount() {
    this.sound.fade(0.5, 0.0, 1000)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
    })
  }

  closeQuestionModal = () => {
    this.setState({
      questionModalIsOpen: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="Anti Social Distance Party" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <ToolBar
          onInfoClick={() =>
            this.setState({
              infoModalIsOpen: true,
            })
          }
          muteSound={() => this.sound.mute(!this.sound.mute())}
        />
        <LoadingBar progress={this.state.progress} />
        <InfoModal
          isOpen={this.state.infoModalIsOpen}
          onRequestClose={this.closeInfoModal}
          title="Anti Social Distance Party"
        >
          <p>
            Eh, excuse me? “Corona” you said? No, I think you’re not on the
            guestlist.
          </p>
          <p>
            The birthday is unavoidable and yearly reoccurring as well. Even in
            times of the current Corona crisis the birthday still happens,
            albeit out of necessity mostly in an improvised renewed version.
            Since January 2020, is Covid-19 worldwide guest at our birthdays. A
            very dominant and demand- ing guest. And, one without invitation.
            Within the framework of a tradition that is foremost about social
            matters is Corona considered a party-pooper in the eyes of most
            people. However, is it truly the most uninvited guest that we ought
            to show the door a.s.a.p.? Or does it also bring in something
            positive? What do we miss and what don’t we miss? Does it help us
            ap- proaching the essence of the birthday?
          </p>
        </InfoModal>
        <QuestionModal
          isOpen={this.state.questionModalIsOpen}
          onRequestClose={this.closeQuestionModal}
          question={this.state.currentQuestion}
        ></QuestionModal>
      </>
    )
  }
}
